import 'reflect-metadata';
import './index.css';

import {Button, Paper, Typography} from '@material-ui/core';
import {lazy, Suspense} from 'react';
import {render} from 'react-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';

import SimpleProgress from './components/SimpleProgress';

const App = lazy(() => import('./App'));

const ROOT_ID = 'root';

function getRoot() {
  const existingRoot = document.getElementById(ROOT_ID);

  if (existingRoot) {
    return existingRoot;
  }

  const newRoot = document.createElement('div');

  newRoot.id = ROOT_ID;
  document.body && document.body.appendChild(newRoot);

  return newRoot;
}

function ErrorFallback({error}: FallbackProps) {
  return (
    <Paper
      role="alert"
      style={{textAlign: 'center', margin: '10px 25px', padding: '25px 0'}}
    >
      <Typography variant="h3">Нажаль виникла помилка:</Typography>
      <pre>{error.message}</pre>
      <Typography>
        Ви можете допомогти нам надіславши відгук з описом ситуації, що призвела
        до помилки чи Вашими припущеннями.
      </Typography>
      <div style={{margin: '1rem'}} />
      <Typography>
        Для продовження роботи спробуйте перезавантажити сторінку.
      </Typography>
      <Button
        variant="outlined"
        onClick={() => location.reload()}
        color="primary"
        style={{marginTop: '15px'}}
      >
        Перезавантажити
      </Button>
    </Paper>
  );
}

function onLoad() {
  render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<SimpleProgress />}>
        <App />
      </Suspense>
    </ErrorBoundary>,
    getRoot(),
  );
}

window.addEventListener('load', onLoad);
